import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable, from, of, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';

const helper = new JwtHelperService();
const TOKEN_KEY = 'jwt-token';
const REFRESH_TOKEN_KEY = 'jwt-refresh-token';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _baseUrl: string;
  public user: Observable<any>;
  private userData = new BehaviorSubject(null);
  private tokenData = new BehaviorSubject<string>('');

  constructor(
    private http: HttpClient,
    @Inject('ENVIRONMENT') private environment: any
  ) {
    this._baseUrl = `${this.environment.apiEndpoint}`;
    this.loadStoredToken();
  }

  loadStoredToken() {
    let token = localStorage.getItem(TOKEN_KEY);
    if (token == 'true') {
      localStorage.removeItem(TOKEN_KEY);
      token = null;
    }

    if (token) {
      this._propagateUserData(token);
    }
  }

  login(login: string, password: string): Observable<any> {
    return this.http
      .post(`${this._baseUrl}/auth/token`, {
        email: login,
        password,
        userType: 'Employee',
      })
      .pipe(
        switchMap((res: any) => {
          if (res.error) {
            if (res.error_description == 'invalid_username_or_password') {
              return throwError('Password or login is incorrect.');
            } else {
              return throwError('Password or login is incorrect.');
            }
          } else {
            return this._storeToken(res);
          }
        })
      );
  }

  logout(): Observable<any> {
    return from(
      Promise.all([
        localStorage.removeItem(TOKEN_KEY),
        localStorage.removeItem(REFRESH_TOKEN_KEY),
      ]).then(() => {
        this.userData.next(null);
      })
    );
  }

  isLoggedIn(): Promise<boolean> {
    const token = localStorage.getItem('jwt-token');
    return Promise.resolve(!!token);
  }

  private _storeToken(tokenResponse: any): Observable<any> {
    this._propagateUserData(tokenResponse.access_token);
    const tokenSave = localStorage.setItem(
      TOKEN_KEY,
      tokenResponse.access_token
    );
    const refreshTokenSave = localStorage.setItem(
      REFRESH_TOKEN_KEY,
      tokenResponse.refresh_token
    );
    const storageObs = from(Promise.all([tokenSave, refreshTokenSave]));

    return storageObs;
  }

  private _propagateUserData(accessToken: string) {
    const decoded = helper.decodeToken(accessToken);
    this.userData.next(decoded);
    this.tokenData.next(accessToken);
  }

  getUser(): any {
    return this.userData.value;
  }

  getJwtToken(): string {
    return this.tokenData.value;
  }

  confirmEmail(email: string, token: string) {
    return this.http.patch(`${this._baseUrl}/Account/ConfirmEmail`, {
      email,
      token,
    });
  }

  forgotPassword(email: string) {
    return this.http.patch(`${this._baseUrl}/Account/ForgotPassword`, {
      email,
    });
  }

  resetPassword(model: any) {
    return this.http.patch(`${this._baseUrl}/Account/ResetPassword`, model);
  }
}
