<div class="app-container">
  <mat-toolbar color="primary" class="app-header">
    <button mat-icon-button (click)="snav.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
    <h1 class="example-app-name">MDB Admin</h1>
    <span class="flex-spacer"></span>
    <button
      matBadge="2"
      matBadgeColor="warn"
      type="button"
      mat-icon-button
      class="me-3"
    >
      <mat-icon>notifications</mat-icon>
    </button>
    <button type="button" mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>account_circle</mat-icon>
    </button>
  </mat-toolbar>

  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav #snav mode="side" opened class="sidenav">
      <mat-nav-list>
        <mat-list-item routerLink="dashboard" routerLinkActive="active">
          <mat-icon mat-list-icon>dashboard</mat-icon>
          <div mat-line>Dashboard</div>
        </mat-list-item>
        <mat-list-item routerLink="orders" routerLinkActive="active">
          <mat-icon mat-list-icon>date_range</mat-icon>
          <div mat-line>Orders</div>
        </mat-list-item>
        <mat-list-item routerLink="clients" routerLinkActive="active">
          <mat-icon mat-list-icon>people</mat-icon>
          <div mat-line>Clients</div>
        </mat-list-item>
        <mat-list-item routerLink="jobs" routerLinkActive="active">
          <mat-icon mat-list-icon>miscellaneous_services</mat-icon>
          <div mat-line>Jobs</div>
        </mat-list-item>
        <!-- <mat-list-item routerLink="assets" routerLinkActive="active">
          <mat-icon mat-list-icon>shopping_bag</mat-icon>
          <div mat-line>Assets</div>
        </mat-list-item> -->
        <mat-list-item routerLink="workers" routerLinkActive="active">
          <mat-icon mat-list-icon>supervised_user_circle</mat-icon>
          <div mat-line>Workers</div>
        </mat-list-item>
        <mat-list-item routerLink="employees" routerLinkActive="active">
          <mat-icon mat-list-icon>shopping_bag</mat-icon>
          <div mat-line>Employees</div>
        </mat-list-item>
        <mat-list-item routerLink="profession" routerLinkActive="active">
          <mat-icon mat-list-icon>shopping_bag</mat-icon>
          <div mat-line>Profession</div>
        </mat-list-item>
        <mat-list-item routerLink="language" routerLinkActive="active">
          <mat-icon mat-list-icon>shopping_bag</mat-icon>
          <div mat-line>Language</div>
        </mat-list-item>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

<mat-menu #menu="matMenu">
  <button mat-menu-item>
    <mat-icon>person</mat-icon>
    <span>Personal info</span>
  </button>
  <button mat-menu-item>
    <mat-icon>settings</mat-icon>
    <span>Settings</span>
  </button>
  <button mat-menu-item (click)="logOut()">
    <mat-icon>logout</mat-icon>
    <span>Logout</span>
  </button>
</mat-menu>
