import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.scss'],
})
export class ConfirmEmailComponent implements OnInit {
  loading = false;
  isConfirmed = false;
  isError = false;
  title = 'Email confirmation';
  successMessage = 'Email confirmed!';

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      const email = params['email'];
      const token = params['token'];
      const language = params['language'];
      this.updateText(language);
      if (!email || !token) {
        alert('incorrect request');
        return;
      }

      this.loading = true;
      this.authService.confirmEmail(email, token).subscribe(
        (res) => {
          this.loading = false;
          this.isConfirmed = true;
        },
        (err) => {
          this.loading = false;
          this.isError = true;
        }
      );
    });
  }

  updateText(language: string) {
    if (language == 'it-it') {
      this.title = 'Conferma via email';
      this.successMessage = 'Email confermata!';

      return;
    }

    if (language == 'de-de') {
      this.title = 'Email Bestätigung';
      this.successMessage = 'E-Mail bestätigt!';

      return;
    }
  }
}
